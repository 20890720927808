<template>

  <div class="actions">


    <UiButtons>

      <template v-if="useAuth().isOwner(props.owner)">
  
        <NuxtLink class="btn" :to="props.link + '/settings'">
          {{ $t('profile_menu_button_settings') }}
        </NuxtLink>

      </template>

      <template v-if="!useAuth().isOwner(props.owner)">
        
        <UiButton        
          @click="complain(props.owner)"
          ico="icon-warning-round"    
          :title="$t('universal_button_complain')"    
        />

        
        <UiButton        
          @click="toChat"
          :name="$t('profile_menu_button_message')"
          ico="icon-message"        
        />
  
        <UiButton
          v-if="!isFollow"
          @click="handleFollowUser"
          :name="$t('profile_menu_follow')"
          type="primary"
          :cnt="props.followers"
          :is-loading="isLoadingFollowUser"
        />
  
        <UiButton
          v-if="isFollow"
          @click="handleUnfollowUser"
          :name="$t('profile_menu_unfollow')"        
          :cnt="props.followers"
          :is-loading="isLoadingFollowUser"
        />
        
      </template>

    </UiButtons>

  </div>
  
</template>

<script setup lang="ts">

import type { User } from '@/types/user';

const { hooks, $complains, $popup } = useNuxtApp()
const { t } = useI18n();
const emits = defineEmits(['followUser', 'unfollowUser']);

interface Props {
  user: User,
  owner: number;
  link: string;
  id: number;
  followers: number;
  isFollow?: boolean | null;
  chatUser?: number;
}

const props = defineProps<Props>();
 
const isLoadingFollowUser = ref(false);

const toChat = function(e) {
  e.preventDefault();
  e.stopPropagation();
  hooks.callHook('chat:start', props.chatUser).catch(() => {
    console.log('chat:start hook');    
  })

}

const complain = (id) => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();

    // Lang.get('chat_complain_confirm'),Lang.get('chat_complain_confirm_text')

  if(!confirm(t('chat_complain_confirm_text')))
    return;

    useLoadingIndicator().start();
  $complains.add('user', id)
  .then((res) => {       
    useLoadingIndicator().finish();
    $popup.success('Complained');
  })
  .catch((res) => {       
    useLoadingIndicator().finish();
    $popup.error('Error');
  });
}


const handleUnfollowUser = async () => {
  isLoadingFollowUser.value = true;
  await useUser().unfollow(props.user);
  isLoadingFollowUser.value = false;
}

const handleFollowUser = async () => {
  isLoadingFollowUser.value = true;
  await useUser().follow(props.user);
  isLoadingFollowUser.value = false;
}

</script>

<style scoped>
 
 .actions{
   display: flex;
   justify-content: center;
  white-space: nowrap;
  flex-shrink: 0;
 }


 @media screen and (max-width: 768px) {
  .actions{
    display: none;
  }
}
</style>
