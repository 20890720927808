<template>

  <SkGrowerOpened
    v-if="statusUserData === 'pending'"
  />

  <template v-else-if="userData">

    <AdvHeader v-if="!useAuth().isOwner(userData?.id)" />
    
    <div class="pbf">
      
      <UserHeadGrower
        :user="userData"      
        @follow-user="handleFollowUser"
        @unfollow-user="handleUnfollowUser"
      />
      
      <div class="pb grid_width">   

        <UiTabs 
          :tabs="tabs" 
          :acttab="activeTab"
        />    

        <br>

        <NuxtPage 
          :user="userData"
          :id="userData.id"
        />
      </div>
        
      <UserContextMenu 
        :is-follow="userData?.is_follow" 
        :user="userData"
        @follow="handleFollowUser"
        @unfollow="handleUnfollowUser"
      />

    </div>
  </template>





</template>

<script setup lang="ts">

import type { User } from '@/types/user'

const route = useRoute();
const router = useRouter();
const { $api, $ga, $helper, $head} = useNuxtApp();
const { t } = useI18n();

var activeTab = ref<string>('info');
var userName = ref<string>(route.params['id'] as string);


const tabs = computed(() => {
  return useUser().getProfileTabs(userData.value);
});


const handleFollowUser = function() {  

  if(!useAuth().isAuth())
    return useAuth().goToLogin();

  useUser().follow(userData.value);
}
 
const handleUnfollowUser = function() {  
  
  if(!useAuth().isAuth())
    return useAuth().goToLogin();

  useUser().unfollow(userData.value);
}

const loadData = async (id: string) => {
  return await $api.getGrowerByName(id);  
}


const { status: statusUserData,  data: userData } = await useLazyAsyncData('userData' + userName.value, async () => await loadData(userName.value) as User, {
  deep: true,
  immediate: true
})


watch( () => userData, () => {
  useHead(useNuxtApp().$head.getGrowerInfo(userData.value));
}, {immediate: true, deep: true});
 
watch( () => router.currentRoute, () => {
  useHead(useNuxtApp().$head.getGrowerInfo(userData.value));
}, {immediate: true, deep: true});


onBeforeUnmount(() => {
  userData.value = null;
})



</script>



<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
